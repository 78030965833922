@import '../../static/rules';

.Classic {
	position: relative;
	margin: auto;
	padding: 50px;
	width: 100%;
  max-width: 700px;
	
	.ChessBoard {
		transform: rotate(0deg);
	}
	
	&.white .ChessBoard {
		transform: rotate(-90deg);
	}
	
	&.black .ChessBoard {
		transform: rotate(90deg);
	}

  .hud {
    margin-top: 15px;
    text-align: center;
    
    a,
    button {
      display: inline-block;
      
      margin:5px 10px;
      padding: 10px 18px;

      outline: none;
      border: none;
      border-radius: 0;
      
      background: $board-dark;
      color: $board-light;
      text-decoration: none;

      transition: all 0.5s ease-in-out;
      cursor: pointer;
      
      &.current {
        background: $hightlight;
      }
    }
  }
}