@import './static/rules';

* {
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

html {
  overflow-x: hidden;
  height: 100%;
}

html,
body {
  position: relative;

  width: 100%;
  min-height: 100vh;

  background: radial-gradient(#fff, #cdcdcd);
  color: #000;

  font-family: 'MinionPro', sans-serif;
  letter-spacing: 0.05em;
  font-size: 12px;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}
