@import '../../../../static/rules';

.TargetPosition {
  position: absolute;
  display: block;
  width: 12.5%;
  height: 12.5%;
  background: $hightlight;
  cursor: pointer;
  z-index: 2;
  font-size: 0;
}