.Home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .hud {
    position: fixed;
    left: 50px;
    top: 50px;

    > * {
      display: block;
      margin-bottom: 10px;
    }
    
    a,
    button {
      display: block;
      cursor: pointer;
      text-decoration: underline;
    }

    p {
      position: fixed;
      right: 15px;
      bottom: 15px;
      color: #757575;
      font-size: 0.8em;
    }
  }
}